@import "reset";

// Mixins
$breakpoints: (
  sm: 500px,
  md: 725px,
  lg: 1001px
);

@mixin respond-to($size) {
  @media only screen and (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

@mixin respond-between($min, $max) {
  @media only screen and (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
    @content;
  }
}

@mixin respond-up-to($size) {
  @media only screen and (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}

// Vars
$globalMaxWidth: 97rem;
$white: #ffffff;
$black: #000000;

// NiH
$nih_red: #c60c30;
$nih_light_gray: #f0f0f0;
$nih_dark_gray: #333;
$darker_red: #810d24;

body {
  background: $nih_light_gray;
}

a {
  color: $nih_dark_gray;
}

.link-list a,
.text-input-label__toggle-visibility,
.message-list__item__link,
.username-list__item__link {
  color: $nih_dark_gray !important;
}

.password-nor-letters-info-box {
  background-color: $nih_red !important;

  &__close-icon {
    button {
      background: $nih_red !important;
    }

    button:hover,
    button:focus {
      background: $darker_red !important;
    }
  }
}

.password-nor-letters-info-container {
  .password-nor-letters-info-arrow {
    @include respond-up-to(lg) {
      border-bottom: 21px solid $nih_red !important;
    }

    @include respond-to(lg) {
      border-right: 20px solid $nih_red !important;
    }
  }
}

// .primaryButton:not([disabled]) {
//   background: $uit_dark_blue !important;

//   &:hover,
//   &:focus {
//     background: $uit_darker_blue !important;
//   }
// }

// .secondaryButton {
//   color: $uit_dark_blue !important;
// }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/OpenSans-Regular.ttf") format("truetype");
}

html {
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
  font-size: 62.5%;
  line-height: 1.5;
}

h1 {
  font-size: 3.6rem;
  line-height: 3.9rem;

  &:first-letter {
    text-transform: capitalize;
  }
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 2.9rem;
  font-weight: normal;
}

p {
  font-size: 1.6rem;
}

body {
  margin: 0;
}

.site-container {
  display: flex;

  &__inner {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

#app-container {
  flex-grow: 1;
  position: relative;

  @include respond-up-to(sm) {
    background: $white;
  }
}

.header {
  color: #fff;
  border-bottom: 2px solid #cacaca;

  @include respond-to(sm) {
    border-bottom: 0;
  }

  background: $nih_red;
  line-height: 1;

  &__main-area {
    padding: 2rem 1rem;
    flex-direction: column;

    @include respond-to(sm) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    @include respond-to(lg) {
      padding-left: 0;
      padding-right: 0;
    }

    display: flex;
    max-width: $globalMaxWidth;
    margin: 0 auto;

    &__app-title {
      font-size: 3.6rem;
      /* line-height: 3.9rem;*/
      font-weight: 600;
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      @include respond-to(sm) {
        margin-top: 0;
      }

      &__item {
        a {
          color: #fff;
        }

        @include respond-to(sm) {
          margin-right: 2rem;
        }
      }
    }
  }

  &__user-info-bar {
    padding: 2rem 1rem;
    padding-top: 0rem;
    padding-bottom: 2rem;
    justify-content: flex-end;
    align-items: center;
    max-width: $globalMaxWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include respond-to(lg) {
      padding-right: 0;
    }

    &__element {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &__divider {
      height: 1.5rem;
      width: 0.1rem;
      background-color: $white;
      margin-left: 1rem;
      margin-right: 1rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__idporten-logo {
      width: 2.5rem;
      height: 2.5rem;
      background-size: contain;

      @include respond-up-to(mg) {
        margin-right: 0rem;
      }
    }

    &__person {
      background-color: $nih_red;
      margin-left: 1rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__item {
      font-size: 1.5rem;

      a {
        color: $white;
      }

      margin-right: 2rem;
      margin-left: 1rem;

      @include respond-up-to(sm) {
        margin-right: 0rem;
        margin-left: 1rem;
      }
    }

    &__hide {
      @extend .header__user-info-bar__item;

      @include respond-up-to(sm) {
        display: none;
      }
    }
  }
}

.header-logo-bar-wrapper {
  background: white;
}

.header-logo-bar__link {
  margin: 0;
  padding: 0;
  color: $white;

  &:hover {
    color: #96c1d1;
    text-decoration: none;
  }
}

.header-logo-bar {
  height: 6rem;
  padding: 1rem 1rem;

  @include respond-to(lg) {
    padding: 1rem 0;
  }

  max-width: $globalMaxWidth;
  margin: 0 auto;
  line-height: 0;
}

.footer__inner-wrapper {
  max-width: 97rem;
  margin: 0 2rem;
  padding: 3rem 0;

  @include respond-to(lg) {
    margin: 0 auto;
  }

  display: block;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  @media only screen and (min-width: 725px) {
    display: flex;
  }
}

.footer {
  margin: 0;
  padding: 1rem;
  background: $nih_dark_gray;
}

.footer-logo {
  margin-right: 2rem;
}

.footer-content {
  color: $white;
  margin-left: 1rem;

  @include respond-to(lg) {
    display: flex;
  }
}

.footer-contact-info,
.footer-maintainer-info,
.footer-terms-info {
  margin-top: 1rem;
  display: block;
  padding-top: 1rem;
  margin-right: 0;

  @media only screen and (min-width: 725px) {
    display: inline-block;
    margin-right: 4rem;
    margin-top: 0;
    padding-top: 0;
  }
}

.info-text {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}

.contact-link,
.uu-info-link,
.cookies-link {
  font-size: 1.6rem;
  margin-top: 1rem;
  color: $white;
  text-decoration: underline;
  border: none;
  box-shadow: none;
  padding: 0;
}

.contact-link:hover,
.context-link:focus {
  background: $white;
  color: $nih_dark_gray;
  text-decoration: none;
}

// Spinner

.sk-circle-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
